import axiosInstance from "../AxiosInstance";

export const getSystemStats = async () => {
    try {
        const response = await axiosInstance.get('/admin/stats');
        return response;
    } catch (error) {
        return error.response;
    }
};
