import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Spin } from 'antd';
import { UserOutlined, CheckCircleOutlined, PercentageOutlined } from '@ant-design/icons';
import { getSystemStats } from '../../Axios/services/Stats';

const Stats = () => {
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchStats();
    }, []);

    const fetchStats = async () => {
        try {
            const response = await getSystemStats();
            if (response.status === 'success') {
                setStats(response.data);
            }
        } catch (error) {
            console.error('Error fetching stats:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="site-card-wrapper">
            <h1 className="text-2xl font-bold mb-6">System Statistics</h1>
            <Row gutter={16}>
                <Col span={8}>
                    <Card bordered={false}>
                        <div className="text-center">
                            <UserOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                            <h2 className="mt-2">Total Invited Users</h2>
                            <p className="text-2xl font-bold text-blue-500">
                                {stats?.totalUsers || 0}
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <div className="text-center">
                            <CheckCircleOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
                            <h2 className="mt-2">Active Users</h2>
                            <p className="text-2xl font-bold text-green-500">
                                {stats?.activeUsers || 0}
                            </p>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card bordered={false}>
                        <div className="text-center">
                            <PercentageOutlined style={{ fontSize: '24px', color: '#722ed1' }} />
                            <h2 className="mt-2">Active Rate</h2>
                            <p className="text-2xl font-bold text-purple-500">
                                {stats?.activePercentage || 0}%
                            </p>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Stats;
